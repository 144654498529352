"use server";

import { getRequestEvent } from "solid-js/web";
import {
  getCategories,
  getCoinsSummary,
  getMinimalClientConfig,
  getProductSearch,
} from "../apis/server_apis";
import { CategoryResult } from "../types/category";
import { BrandGroup, Mode } from "~/types";
import { getMarkdown } from "../static";
import { GroupDetails } from "../types/groups";
import { SearchResults } from "../types/search";
import { CoinsSummaryResponse } from "../types/order";
import { ClientMinimalConfigOld } from "../types/brand";

export type GroupsRouteData = {
  categoryList: CategoryResult;
  isLoggedIn: boolean;
  groupDetails: GroupDetails;
  brandList: SearchResults;
  coinsSummary?: CoinsSummaryResponse;
  mode: Mode;
  clientConfig?: ClientMinimalConfigOld;
  clientId?: string;
};

export const getGroupsRouteData = async (
  group: BrandGroup,
  groupName: string
): Promise<GroupsRouteData> => {
  let coinsSummary$P: Promise<CoinsSummaryResponse> | undefined;
  if (getRequestEvent()?.locals.sid !== undefined) {
    coinsSummary$P = getCoinsSummary();
  }
  const [categoryList, groupDetails, coinsSummary] = await Promise.all([
    getCategories(),
    getMarkdown(
      `webstore/${group}s/${groupName}.json`
    ) as Promise<GroupDetails>,
    coinsSummary$P ?? Promise.resolve(undefined),
  ]);
  const requestEvent = getRequestEvent();
  const mode = requestEvent?.locals.mode ?? "hubble";

  let clientConfig: ClientMinimalConfigOld | undefined;

  if (mode === "rnr" && requestEvent?.locals.clientId) {
    clientConfig = await getMinimalClientConfig(requestEvent.locals.clientId);
  }

  const [brandList] = await Promise.all([
    getProductSearch({ brandKey: groupDetails.brandKeys }),
  ]);

  const isLoggedIn = getRequestEvent()?.locals.sid !== undefined;

  return {
    isLoggedIn,
    groupDetails,
    categoryList,
    clientConfig,
    mode,
    brandList,
    coinsSummary,
    clientId: requestEvent?.locals?.clientId,
  };
};
